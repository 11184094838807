import PageHeader from "../shared/PageHeader"
const Transwerke = ()=>{
    return(
        <div className="w-full">
            <PageHeader title="TRANSWERKE"/>
            <div className="max-w-screen-2xl mx-auto">
                <div className="max-w-4xl mx-auto pb-10 mt-8">
                    <section className="font-bold bg-gray-700 text-slate-200 py-2 px-2 rounded">EVENT DETAILS</section>  
                    <div className="flex flex-col">
                        <div className="flex flex-col">
                            <label htmlFor="">NAME OF EVENT</label>
                            <input type="text" name="EventName" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                        <div className="flex flex-col">   
                            <label htmlFor="">EVENT DATE</label>
                            <input type="date" name="EventDate" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>

                        </div>
                    </div>   

                    <div className="flex flex-col">
                        <div className="flex flex-col">
                            <label htmlFor="">VENDOR NAME</label>
                            <input type="text" name="VendorName" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                        <div className="flex flex-col">   
                            <label htmlFor="">DAYS DURATION</label>
                            <input type="number" name="DaysDuration" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>

                        </div>
                    </div> 

                    <section className="font-bold mt-5 bg-gray-700 text-slate-200 py-2 px-2 rounded">OWNER'S PERSONAL DATA</section>  
                    <div className="flex flex-col">
                        <div className="flex flex-col">
                            <label htmlFor="">VENDOR FULL NAME</label>
                            <input type="text" name="VendorName" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                        <div className="flex flex-col">   
                            <label htmlFor="">ID NUMBER</label>
                            <input type="number" name="DaysDuration" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>

                        </div>

                        <div className="flex flex-col">   
                            <label htmlFor="">ADDRESS</label>
                            <input type="number" name="DaysDuration" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>

                        <div className="flex flex-col">   
                            <label htmlFor="">REGION</label>
                            <select name="" id="" className="border border-slate-300 rounded p-1 w-[100%]">
                                <option value=""></option>
                                <option value="GAUTENG">GAUTENG</option>
                                <option value="LIMPOPO">LIMPOPO</option>
                            </select>
                        </div>
                        <div className="flex flex-col">   
                            <label htmlFor="">CITY</label>
                            <input type="number" name="DaysDuration" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                        <div className="flex flex-col">   
                            <label htmlFor="">POSTAL CODE</label>
                            <input type="number" name="DaysDuration" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                        <div className="flex flex-col">   
                            <label htmlFor="">PHONE NUMBER</label>
                            <input type="text" name="DaysDuration" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                        <div className="flex flex-col">   
                            <label htmlFor="">COMPANY REGISTRATION NUMBER</label>
                            <input type="number" name="DaysDuration" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                        <div className="flex flex-col">   
                            <label htmlFor="">BBBEE LEVEL</label>
                            <input type="number" name="DaysDuration" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                    </div>   

                    <section className="font-bold mt-5 bg-gray-700 text-slate-200 py-2 px-2 rounded">EMPLOYMENT OPPORTUNITIES CREATED BY ENTERPRISE</section>  
                    <div className="flex flex-col">
                        <label htmlFor="">NAME</label>
                        <input type="text" placeholder="Name" className="border border-slate-300 rounded p-1"/>
                        <label htmlFor="">ROLE</label>
                        <input type="text" placeholder="Role" className="border border-slate-300 rounded p-1"/>
                        <label htmlFor="">DURATION</label>
                        <input type="text" placeholder="DURATION" className="border border-slate-300 rounded p-1"/>
                        <label htmlFor="">ID NUMBER</label>
                        <input type="text" placeholder="ID NUMBER" className="border border-slate-300 rounded p-1"/>
                        <label htmlFor="">ID COPY</label>
                        <input type="file" placeholder="ID COPY" className="border border-slate-300 rounded p-1"/>
                        <div className="flex mt-1 items-end justify-end">
                            <button className="py-1 px-2 bg-green-600 rounded text-white border shadow">Add Employment Opportunity</button>
                        </div>
                    </div>
                    <div className="border mt-8 py-1 px-2">
                        <table className="">
                            <tr>
                                <th className="w-[24%]">Name</th>
                                <th className="w-[24%]">Role</th>
                                <th className="w-[24%]">ID Number</th>
                                <th className="w-[24%]">DURATION</th>
                                <th className=""></th>
                            </tr>
                            <tr className="border-b border-slate-300">
                                <td className="w-[24%] text-center">James Doe</td>
                                <td className="w-[24%]  text-center">Manager</td>
                                <td className="w-[24%]  text-center">1234565465465456</td>
                                <td className="w-[24%]  text-center">2</td>
                                <td>
                                    <button className="py-1 px-1 bg-red-600 rounded text-white border shadow m-1">Delete</button>
                                </td>
                            </tr>
                            <tr className="border-b border-slate-300">
                                <td className="w-[24%] text-center">James Doe</td>
                                <td className="w-[24%]  text-center">Manager</td>
                                <td className="w-[24%]  text-center">1234565465465456</td>
                                <td className="w-[24%]  text-center">1</td>
                                <td>
                                    <button className="py-1 px-1 bg-red-600 rounded text-white border shadow m-1">Delete</button>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div className="flex flex-col mt-3">
                        <div className="flex flex-col">
                            <label htmlFor="">TOTAL EMPLOYMENT OPPORTUNITIES CREATED</label>
                            <input type="number" name="VendorName" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Transwerke