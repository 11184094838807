import { Link } from "react-router-dom";
import PageHeader from "../shared/PageHeader"
import Declaration from "./Declaration";

const GigPortal = ()=>{
    return(
        <div className="w-full">
            <PageHeader title="Gig Portal"/>
            <div className="max-w-screen-xl mx-auto md:px-4 md:pt-10 pt-5 flex items-center justify-center">
                <div className="flex flex-col justify-center  md:grid md:grid-cols-3 md:gap-10 md:mt-[8%] w-full px-4 md:pb-10">
                    <Link to="/portal/market">
                        <div style={{backgroundImage:"url('./assets/Markets.png')"}} className="mb-2 md:mb-0 md:w-72 md:h-72 w-full h-52 bg-cover bg-center border rounded shadow-2xl flex items-end  md:justify-end">
                            <div className="group-open:bg-red-300 text-center font-bold bg-black/70 backdrop-blur text-white py-3 px-2 w-full">FESTIVAL MARKETS</div>
                        </div>
                    </Link>
                    <Link to='/portal/event'>
                        <div style={{backgroundImage:"url('./assets/Events.png')"}} className="mb-2 md:mb-0 md:w-72 md:h-72 w-full h-52 bg-cover bg-center border rounded shadow-2xl flex items-end justify-end">
                            <div className="text-center font-bold bg-black/70 backdrop-blur text-white py-3 px-2 w-full">FESTIVAL EVENTS</div>
                        </div>
                    </Link>
                    <Link to='/portal/transwerke'>
                        <div style={{backgroundImage:"url('./assets/Transwerke.png')"}} className="mb-2 md:mb-0 md:w-72 md:h-72 w-full h-52 bg-cover bg-center border rounded shadow-2xl flex items-end justify-end">
                            <div className="text-center font-bold bg-black/70 backdrop-blur text-white py-3 px-2 w-full">TRANSWERKE STUDIO</div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default GigPortal;