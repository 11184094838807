import PageHeader from "../shared/PageHeader";
const Business = ()=>{
    return(
        <div>
            <PageHeader title="SMMEs"/>
            <div className="max-w-screen-2xl mx-auto pb-10 px-4">
                <div className="grid md:grid-cols-4 grid-cols-1 mt-10 gap-3">
                    <div className="min-w-[80%] md:min-w-[40%]">
                        <div style={{backgroundImage: "url('/assets/art3.jpg')"}} className="h-80 w-full rounded-lg bg-cover bg-center shadow-md shadow-black/20 relative">
                            <div className="absolute bg-stone-300/20 w-full text-center bottom-0 text-white backdrop-blur-sm pb-2">
                                <h5 className="mt-2">Lights in the dark</h5>
                            </div>
                        </div>
                        
                    </div>
                    <div className="min-w-[80%] md:min-w-[40%]">
                        <div style={{backgroundImage: "url('/assets/art1.jpg')"}} className="h-80 w-full rounded-lg bg-cover bg-center shadow-md shadow-black/20 relative">
                            <div className="absolute bg-stone-300/20 w-full text-center bottom-0 text-white  pb-2">
                                <h5 className="mt-2">Lights in the dark</h5>
                            </div>
                        </div>
                    </div>

                    <div className="min-w-[80%] md:min-w-[40%]">
                        <div style={{backgroundImage: "url('/assets/art2.jpg')"}} className="h-80 w-full rounded-lg bg-cover bg-center shadow-md shadow-black/20 relative">
                            <div className="absolute bg-stone-300/20 w-full text-center bottom-0 text-white backdrop-blur-sm pb-2">
                                <h5 className="mt-2">Lights in the dark</h5>
                            </div>
                        </div>   
                    </div>
                    <div className="min-w-[80%] md:min-w-[40%]">
                        <div style={{backgroundImage: "url('/assets/art3.jpg')"}} className="h-80 w-full rounded-lg bg-cover bg-center shadow-md shadow-black/20 relative">
                            <div className="absolute bg-stone-300/20 w-full text-center bottom-0 text-white backdrop-blur-sm pb-2">
                                <h5 className="mt-2">Lights in the dark</h5>
                            </div>
                        </div>
                        
                    </div>
                    <div className="min-w-[80%] md:min-w-[40%]">
                        <div style={{backgroundImage: "url('/assets/art1.jpg')"}} className="h-80 w-full rounded-lg bg-cover bg-center shadow-md shadow-black/20 relative">
                            <div className="absolute bg-stone-300/20 w-full text-center bottom-0 text-white  pb-2">
                                <h5 className="mt-2">Lights in the dark</h5>
                            </div>
                        </div>
                    </div>

                    <div className="min-w-[80%] md:min-w-[40%]">
                        <div style={{backgroundImage: "url('/assets/art2.jpg')"}} className="h-80 w-full rounded-lg bg-cover bg-center shadow-md shadow-black/20 relative">
                            <div className="absolute bg-stone-300/20 w-full text-center bottom-0 text-white backdrop-blur-sm pb-2">
                                <h5 className="mt-2">Lights in the dark</h5>
                            </div>
                        </div>   
                    </div>

                    <div className="min-w-[80%] md:min-w-[40%]">
                        <div style={{backgroundImage: "url('/assets/gallery6.jpg')"}} className="h-80 w-full rounded-lg bg-cover bg-center shadow-md shadow-black/20 relative">
                            <div className="absolute bg-stone-300/20 w-full text-center bottom-0 text-white backdrop-blur-sm pb-2">
                                <h5 className="mt-2">Lights in the dark</h5>
                            </div>
                        </div>
                    </div>

                    <div className="min-w-[80%] md:min-w-[40%]">
                        <div style={{backgroundImage: "url('/assets/gallery6.jpg')"}} className="h-80 w-full rounded-lg bg-cover bg-center shadow-md shadow-black/20 relative">
                            <div className="absolute bg-stone-300/20 w-full text-center bottom-0 text-white backdrop-blur-sm pb-2">
                                <h5 className="mt-2">Lights in the dark</h5>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    )
}

export default Business;