import HomeImage from "../../assets/home.jpg";
const Home = ()=>{
    return(
        <div>
            <div style={{backgroundImage: "url('/assets/home.jpg')"}} className="bg-cover bg-center flex justify-center items-center md:h-[calc(100vh-0px)] h-[calc(100vh-500px)]">
                <div className="w-full h-full bg-stone-300/20">
                    <div className="w-full h-full flex justify-center items-center">
                        <div className="flex md:justify-center md:items-center flex-col">
                            <div className="bg-center bg-cover w-full md:h-96 h-80 border border-black" style={{backgroundImage:"url('/assets/Logo_black_white.png')"}}>
                            </div>
                            <div className=" text-5xl font-bold text-white">
                                <span className="hidden md:block">Braamfontein | Johannesburg | Gauteng</span>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        
    
    )
}

export default Home;