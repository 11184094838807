import PageHeader from "../shared/PageHeader"
const FestivalEvent = ()=>{
    return(
        <div className="w-full">
            <PageHeader title="FESTIVALS EVENT"/>
            <div className="max-w-screen-2xl mx-auto md:px-0 px-2">
                <div className="max-w-4xl mx-auto pb-10 mt-8">
                    <section className="font-bold bg-gray-700 text-slate-200 py-2 px-2 rounded text-lg">EVENT DETAILS</section>  
                    <div className="flex flex-col">
                        <div className="flex flex-col">
                            <label htmlFor="">NAME OF EVENT</label>
                            <input type="text" name="EventName" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="">EVENT PRODUCTION COMPANY</label>
                            <input type="text" name="EventName" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                        <div className="flex flex-col">   
                            <label htmlFor="">EVENT DATE</label>
                            <input type="date" name="EventDate" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>

                        </div>
                    </div>   

                    <section className="font-bold mt-5 bg-gray-700 text-slate-200 py-2 px-2 rounded text-lg">EVENT ORGANISOR'S PERSONAL DATA</section>  
                    <div className="flex flex-col">
                        <div className="flex flex-col">
                            <label htmlFor="">FULL NAME</label>
                            <input type="text" name="VendorName" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                        <div className="flex flex-col">   
                            <label htmlFor="">ID NUMBER</label>
                            <input type="number" name="DaysDuration" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>

                        </div>

                        <div className="flex flex-col">   
                            <label htmlFor="">ADDRESS</label>
                            <input type="number" name="DaysDuration" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>

                        <div className="flex flex-col">   
                            <label htmlFor="">REGION</label>
                            <select name="" id="" className="border border-slate-300 rounded p-1 w-[100%]">
                                <option value=""></option>
                                <option value="GAUTENG">GAUTENG</option>
                                <option value="LIMPOPO">LIMPOPO</option>
                            </select>
                        </div>
                        <div className="flex flex-col">   
                            <label htmlFor="">CITY</label>
                            <input type="number" name="DaysDuration" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                        <div className="flex flex-col">   
                            <label htmlFor="">POSTAL CODE</label>
                            <input type="number" name="DaysDuration" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                        <div className="flex flex-col">   
                            <label htmlFor="">PHONE NUMBER</label>
                            <input type="text" name="DaysDuration" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                        <div className="flex flex-col">   
                            <label htmlFor="">COMPANY REGISTRATION NUMBER</label>
                            <input type="number" name="DaysDuration" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                        <div className="flex flex-col">   
                            <label htmlFor="">BBBEE LEVEL</label>
                            <input type="number" name="DaysDuration" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                    </div>   

                    <section className="font-bold mt-5 text-lg bg-gray-700 text-slate-200 py-2 px-2 rounded">EMPLOYMENT OPPORTUNITIES CREATED AT THE EVENT</section>  
                    <hr />
                    <div className="grid md:grid-cols-3 grid-cols-1 md:gap-2 pr-6 md:pr-0">
                        <div>
                            <h5 className="text-base">PRODUCTION</h5>
                            <div className="flex justify-between items-center">
                                <span>PERMANENT JOBS</span>
                                <input type="checkbox" name="" id="" className="h-4 w-4" />
                            </div>
                            <div className="flex justify-between items-center">
                                <span>GIG WORK</span>
                                <input type="checkbox" name="" id="" className="h-4 w-4" />
                            </div>
                        </div>

                        <div>
                            <h5 className="text-base">TECHNICAL</h5>
                            <div className="flex justify-between items-center">
                                <span>PERMANENT JOBS</span>
                                <input type="checkbox" name="" id="" className="h-4 w-4" />
                            </div>
                            <div className="flex justify-between items-center">
                                <span>GIG WORK</span>
                                <input type="checkbox" name="" id="" className="h-4 w-4" />
                            </div>
                        </div>

                        <div>
                            <h5 className="text-base">OPERATIONS</h5>
                            <div className="flex justify-between items-center">
                                <span>PERMANENT JOBS</span>
                                <input type="checkbox" name="" id="" className="h-4 w-4" />
                            </div>
                            <div className="flex justify-between items-center">
                                <span>GIG WORK</span>
                                <input type="checkbox" name="" id="" className="h-4 w-4" />
                            </div>
                        </div>

                        <div>
                            <h5 className="text-base">MARKETING</h5>
                            <div className="flex justify-between items-center">
                                <span>PERMANENT JOBS</span>
                                <input type="checkbox" name="" id="" className="h-4 w-4" />
                            </div>
                            <div className="flex justify-between items-center">
                                <span>GIG WORK</span>
                                <input type="checkbox" name="" id="" className="h-4 w-4" />
                            </div>
                        </div>

                        <div>
                            <h5 className="text-base">CONTENT CREATION</h5>
                            <div className="flex justify-between items-center">
                                <span>PERMANENT JOBS</span>
                                <input type="checkbox" name="" id="" className="h-4 w-4" />
                            </div>
                            <div className="flex justify-between items-center">
                                <span>GIG WORK</span>
                                <input type="checkbox" name="" id="" className="h-4 w-4" />
                            </div>
                        </div>

                        <div>
                            <h5 className="text-base">TALENT</h5>
                            <div className="flex justify-between items-center">
                                <span>PERMANENT JOBS</span>
                                <input type="checkbox" name="" id="" className="h-4 w-4" />
                            </div>
                            <div className="flex justify-between items-center">
                                <span>GIG WORK</span>
                                <input type="checkbox" name="" id="" className="h-4 w-4" />
                            </div>
                        </div>
                    </div>   

                    {/* Categories goes here */}
                    <div className="py-2 px-2 bg-gray-700 mt-5 rounded">
                        <h5 className="text-base text-orange-400 underline text-center">CATEGORIES</h5>
                        
                        <div className="flex flex-col">
                            <h5 className="text-sm text-orange-400 flex items-center">1.<span className="text-base text-slate-200 flex items-center px-1">PRODUCTION:</span></h5> 
                            <div className="flex items-center px-3 text-slate-200">
                                Festival, Artistic, Creative Directors | Talent Booker | Artists 
                                Liaison & Travel| Artists Logistics | Runners 
                            </div>

                            <h5 className="text-sm text-orange-400 flex items-center">2.<span className="text-base text-slate-200 flex items-center px-1">TECHNICAL:</span></h5> 
                            <div className="flex items-center px-3 text-slate-200">
                                Audio, Light and Visual Managers | Stage Manager | Backliners 
                                |Rigging; lighting; sound crew.
                            </div>

                            <h5 className="text-sm text-orange-400 flex items-center">3.<span className="text-base text-slate-200 flex items-center px-1">OPERATIONS:</span></h5> 
                            <div className="flex items-center px-3 text-slate-200">
                                Site Manager/s | Staff Manager/s | Safety & Security | Cleaners 
                                & General Workers; Hospitality; Vendors. 
                            </div>

                            <h5 className="text-sm text-orange-400 flex items-center">4.<span className="text-base text-slate-200 flex items-center px-1">MARKETING:</span></h5> 
                            <div className="flex items-center px-3 text-slate-200">
                                Marketing & Communications |Social Media| PRI Sponsorship. 
                            </div>

                            <h5 className="text-sm text-orange-400 flex items-center">5.<span className="text-base text-slate-200 flex items-center px-1">CONTENT CREATORS:</span></h5> 
                            <div className="flex items-center px-3 text-slate-200">
                                Photographers | Videographers and crew.
                            </div>

                            <h5 className="text-sm text-orange-400 flex items-center">6.<span className="text-base text-slate-200 flex items-center px-1">TALENT:</span></h5> 
                            <div className="flex items-center px-3 text-slate-200">
                                Artists; Musicians; Dancers etc.
                            </div>
                        </div>
                   
                    </div>

                    <div className="flex flex-col mt-3">
                        <div className="flex flex-col">
                            <label htmlFor="">TOTAL EMPLOYMENT OPPORTUNITIES CREATED</label>
                            <input type="number" name="VendorName" id="" className="border border-slate-300 rounded p-1 w-[100%]"/>
                        </div>
                    </div>
                    <div className="flex mt-1 items-end justify-end">
                        {/* <label htmlFor="">TOTAL EMPLOYMENT OPPORTUNITIES CREATED</label>
                        <input type="checkbox" name="" id="" /> */}
                        <button className="py-1 px-2 bg-green-600 rounded text-white border shadow md:w-auto w-full">SUBMIT</button>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default FestivalEvent
