import PageHeader from "../shared/PageHeader";

const NotFound = ()=>{
    return(
        <div>
            <PageHeader title="404 - Page Not Found"/>
            <div className="flex justify-center items-center pt-5">
                <h4>Oops, we didn't find what you were looking for.</h4>
            </div>
        </div>
    )
}

export default NotFound;