import {useNavigate} from "react-router-dom";
import PageHeader from "../shared/PageHeader";

const NewsOpportunities = ()=>{
    const navigate = useNavigate();
    const readArticle = (id)=>{
        navigate(`/news/${id}`)
    }
    return(
        <div className="w-full">
            <PageHeader title="NEWS & OPPORTUNITIES"/>  
            <div className="max-w-screen-xl mx-auto px-4 pt-3">
                <div className="shadow-md mt-3">
                    <div className="flex gap-2 md:flex-row flex-col">
                        <div className="flex-1p bg-cover bg-center rounded-tr-md rounded-br-md md:hidden block w-full h-40" style={{backgroundImage:"url('assets/art3.jpg')"}}></div>    
                        <div className="md:w-[70%] py-2 px-3 border-b-2">
                            <h4>Test title goes here</h4>
                            <h5>By Tebogo Legodi</h5>
                            <p>
                            Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
                            Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
                            </p>
                            <div className="flex justify-end">
                                <button className="shadow-sm py-2 px-3 bg-zinc-800 text-zinc-200" onClick={()=>readArticle(2)}>Continue Reading</button>
                            </div>
                        </div>
                        <div className="flex-1 bg-cover bg-center rounded-tr-md rounded-br-md hidden md:block" style={{backgroundImage:"url('assets/art3.jpg')"}}></div>    
                    </div>
                    <div className="flex md:flex-row flex-col gap-2">
                        <div className="flex-1p bg-cover bg-center rounded-tr-md rounded-br-md md:hidden block w-full h-40" style={{backgroundImage:"url('assets/art2.jpg')"}}></div> 
                        <div className="md:w-[70%] py-2 px-3 border-b-2">
                            <h4>Test title goes here</h4>
                            <h5>By Tebogo Legodi</h5>
                            <p>
                            Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
                            Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
                            </p>
                            <div className="flex justify-end">
                                <button className="shadow-sm py-2 px-3 bg-zinc-800 text-zinc-200">Continue Reading</button>
                            </div>
                        </div>
                        <div className="flex-1 bg-cover bg-center  rounded-tr-md rounded-br-md hidden md:block" style={{backgroundImage:"url('assets/art2.jpg')"}}></div>    
                    </div>
                    <div className="flex gap-2 md:flex-row flex-col">
                        <div className="flex-1p bg-cover bg-center rounded-tr-md rounded-br-md md:hidden block w-full h-40" style={{backgroundImage:"url('assets/art2.jpg')"}}></div> 
                        <div className="md:w-[70%] py-2 px-3 border-b-2">
                            <h4>Test title goes here</h4>
                            <h5>By Tebogo Legodi</h5>
                            <p>
                            Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
                            Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
                            </p>
                            <div className="flex justify-end">
                                <button className="shadow-sm py-2 px-3 bg-zinc-800 text-zinc-200">Continue Reading</button>
                            </div>
                        </div>
                        <div className="flex-1 bg-cover bg-center  rounded-tr-md rounded-br-md hidden md:block" style={{backgroundImage:"url('assets/art1.jpg')"}}></div>    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsOpportunities;