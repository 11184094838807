import logo from "../../assets/Logo_black_white.png";
const PageHeader = (props)=>{
    return(
        <div className="w-full bg-black">
            <div className="max-w-screen-xl mx-auto">
                <div className="flex flex-row items-center justify-center md:justify-start gap-4 py-2 px-2">
                    <div className="bg-white rounded-full shadow-lg md:block hidden">
                        <img src={logo} alt="Logo Black" className="w-28" />
                    </div>
                    <div className=" text-white py-4 md:px-3">
                        <div className="border-b-2">
                            <h1 className="text-2xl text-center md:text-left">{props.title}</h1>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PageHeader;