import { useState, useEffect } from "react";
import {useParams} from "react-router-dom";
import PageHeader from "../shared/PageHeader"

const NewsOpportunityView = (props)=>{
    const [title, setTitle] = useState("Loading Event...");
    let {id} = useParams();
    useEffect(()=>{
        getId();
    },[id]);

    const getId = ()=>{
        console.log(id);
    }
    return(
        <>
            <PageHeader title={title}/>
        </>
    )
};

export default NewsOpportunityView;