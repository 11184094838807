import PageHeader from "../shared/PageHeader"
const Residency = ()=>{
    return(
        <div className="w-full mb-10">
            <PageHeader title="RESIDENCY"/>
            <div className="max-w-screen-2xl mx-auto pt-10">
                <div className="text-xl shadow px-5 py-6">
                    The <span className="font-bold italic">Creative Uprising Residency</span> is a response to much needed support systems and infrastructure in
                    the creative industry for creatives. It aims to bridge the gaps that exist between industry, market, and
                    practice. The creative industry is responsible for a significant contribution towards the GDP of South
                    Africa, but yet, creatives and other stakeholders in the creative industry always operate from a
                    precarious and volatile positions because of the lack of formalization, and infrastructure that is
                    specifically responsive to the needs of those in the creative sector. <br/><br/>

                    The <span className="font-bold italic">Creative Uprising Residency</span> is an artist incubator programme that supports emerging creatives in
                    the visual arts. It provides support services including, but not limited to : studio sponsorship up to a
                    month, access to industry mentors and leaders in the visual arts, access to funding opportunities, and a
                    solo exhibition at the Constitution Hill Precinct.
                </div>
                {/* <div className="grid grid-cols-2 mt-11 gap-4 relative px-2">
                   
                    <div className="flex relative">
                        <div className="absolute top-0 border-t-2 border-black w-[80%]"></div>
                        <div className="absolute bottom-0 border-t-2 border-black w-[70%] right-0"></div>
                        <div className="flex flex-col items-center bg-black text-white py-2 px-4 justify-between">
                            <div>P</div>
                            <div>R</div>
                            <div>O</div>
                            <div>J</div>
                            <div>E</div>
                            <div>C</div>
                            <div>T</div>
                            <div>-</div>
                            <div>G</div>
                            <div>O</div>
                            <div>A</div>
                            <div>L</div>
                            <div>S</div>
                        </div>
                        <div className="flex items-start justify-center px-2 flex-col space-y-6">
                            <div className="font-bold flex items-center">
                                <span className="text-xl">To provide visual artists with working space</span>
                            </div>
                            <div className="font-bold flex items-center">
                                <span  className="text-xl">To provide visual artists with access to market opportunities through various access to market
                                programs such as solo exhibitions, newsletter, website , and through strategic partnerships</span>
                            </div>
                            <div className="font-bold"><span className="text-xl">Knowledge and skills sharing through mentorships by creative industry and business specialists</span></div>
                        </div>
                    </div>

                    <div className="flex relative justify-end">
                        <div className="absolute top-0 border-t-2 border-black w-[80%] right-0"></div>
                        <div className="absolute bottom-0 border-t-2 border-black w-[70%] left-0"></div>
                        
                        <div className="flex items-end justify-center px-2 flex-col space-y-6">
                            <div className="font-bold"><span className="text-xl">Studio practice i.e production of work</span></div>
                            <div className="font-bold"><span className="text-xl">Studio visits</span></div>
                            <div className="font-bold"><span className="text-xl">Exhibition</span></div>
                            <div className="font-bold"><span className="text-xl">Exhibition programming i.e Walkabout, talk series, etc</span></div>
                        </div>

                        <div className="flex flex-col items-center justify-between bg-black text-white py-2 px-4">
                            <div>P</div>
                            <div>R</div>
                            <div>O</div>
                            <div>J</div>
                            <div>E</div>
                            <div>C</div>
                            <div>T</div>
                            <div>-</div>
                            <div>A</div>
                            <div>C</div>
                            <div>T</div>
                            <div>I</div>
                            <div>V</div>
                            <div>I</div>
                            <div>T</div>
                            <div>I</div>
                            <div>E</div>
                            <div>S</div>
                        </div>
                    </div>
                        
                </div> */}
                                
                {/* <div className="text-xl mt-10 shadow border px-5 py-6">
                    <h3>EXPECTED RESULTS</h3>
                    <div className="mb-5">
                        Expected results from the arts incubator programme is to formalize artists creative business by
                        providing access to market and opportunities that will enable these businesses to compete on a local
                        and national scale. 
                    </div>

                    <h3 className="mt-10">BUDGET ESTIMATE</h3>
                    <div className="mb-5">
                        The below line items are not exhaustive, but those that are critical to the studio production process and
                        production of exhibition: 
                        <div className="flex flex-col pl-10 mt-2">
                            <div className="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                                </svg>
                                <span>PRODUCTION FESS</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                                </svg>
                                <span>STUDIO RENT</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                                </svg>
                                <span>EXHIBITION PRODUCTION <small>(installation, signage, packaging, transportation)</small></span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                                </svg>
                                <span>HOSPITALITY</span>
                            </div>
                        </div>
                    </div>

                    <h3 className="mt-10">GIG WORK OPPORTUNITIES GENERATED BY PROJECT</h3>
                    <div className="mb-5">
                        The below are gig work opportunities that can be generated by a single incubation: 
                        <div className="flex flex-col pl-10 mt-2">
                            <div className="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                                </svg>
                                <span>ARTIST TEAM</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                                </svg>
                                <span>INSTALLATION TEAM</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                                </svg>
                                <span>INVITED INDUSTRY SPECIALISTS</span>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Residency