import PageHeader from "../shared/PageHeader";
const About = ()=>{
    return(
        <div className="pb-3">
            <PageHeader title="ABOUT US"/>
            <div className="max-w-screen-xl mx-auto pb-3 px-4">
                <div className="grid md:grid-cols-2 grid-cols-1 mt-3 shadow-md">
                    <div  className="bg-cover bg-center md:hidden">
                        <img src="assets/art3.jpg" alt="" />
                    </div>
                    <div className="flex items-center px-3 py-3">
                        <div className="">
                        Constitution Hill is a subsidiary of the Gauteng Growth and Development Agency (GGDA), part of the Gauteng Department of Economic Development. Constitution Hill is a national heritage and activism precinct that facilitates economic growth through it’s various programs and interventions.<br/><br></br>
                        The Creative Uprising Hub is an incubation Hub that supports creative SMMEs in the broader Gauteng Region. It’s core mandates is to support and facilitate access to market opportunities for creative SMMEs that are incubated at the Hub.<br/><br></br>
                        The website forms part of our digital and marketing strategies that aims to provide a wider audience reach and thus opportunities for a wider access to market. It will also host the creative hub’s online market place where incubated businesses will have a platform to sell their products.
                        </div>
                        
                    </div>
                    <div  className="bg-cover bg-center hidden md:block">
                        <img src="assets/art3.jpg" alt="" />
                    </div>
                </div>
                <div className="mt-3">
                    <div className="grid md:grid-cols-2 gap-4">
                        <div>
                            <form>
                                <div className="mb-2 flex flex-col">
                                    <label htmlFor="">Name</label>
                                    <input type="text" name="" id="" className="border border-zinc-500 py-2 px-3"/>
                                </div>
                                <div className="mb-2 flex flex-col">
                                    <label htmlFor="">Email</label>
                                    <input type="email" name="" id="" className="border border-zinc-500 py-2 px-3"/>
                                </div>
                                <div className="mb-2 flex flex-col">
                                    <label htmlFor="">Message</label>
                                    <textarea name="" id="" className="border border-zinc-500 py-2 px-3" rows="5"></textarea>
                                </div>
                                <div className="mb-2 flex flex-col">
                                    <button className="bg-black text-zinc-200 py-3 px-2 rounded-sm mt-2 hover:shadow-md" type="button">Send Message</button>
                                </div>
                            </form>
                        </div>

                        <div className="flex items-center justify-center flex-col md:py-0 py-3">
                            <div>
                                <h5>Follow us on Social Media</h5>
                            </div>
                            <div className="flex mt-3">
                                <div className="px-3">
                                    <img src="assets/facebook.png" height="50" width="50"/>
                                </div>
                                <div className="px-3">
                                    <img src="assets/twitter.png" height="50" width="50"/>
                                </div>
                                <div className="px-3">
                                    <img src="assets/instagram.png" height="50" width="50"/>
                                </div>
                                
                                
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="w-full">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3580.2339832873013!2d28.040523515574893!3d-26.18906576971961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950c22f736070b%3A0xef2f5879189176f4!2sConstitution%20Hill%20Human%20Rights%20Precinct!5e0!3m2!1sen!2sza!4v1670248549176!5m2!1sen!2sza" height="450" className="w-full mt-4"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
}

export default About;