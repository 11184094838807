import { Routes, Route} from "react-router-dom";
import About from "./components/about/About";
import Business from "./components/business/Business";
import Events from "./components/events/Events";
import Home from "./components/home/Home";
import Landing from "./components/Landing";
import NewsOpportunities from "./components/news_opportunities/NewsOpportunities";
import NotFound from "./components/notFound/NotFound";
import NewsOpportunityView from "./components/news_opportunities/NewsOpportunityView";
import GigPortal from "./components/gig_portal/GigPortal";
import FestivalEvent from "./components/gig_portal/FestivalEvent";
import Residency from "./components/residency/Residency";
import FestivalMarket from "./components/gig_portal/FestivalMarket";
import Transwerke from "./components/gig_portal/Transwerke";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Landing/>}>
          <Route index element={<Home/>}/>
          <Route path="residency" element={<Residency/>}/>
          <Route path="events" element={<Events/>}/>
          <Route path="news">
            <Route index={true} element={<NewsOpportunities/>}/>
            <Route path="/news/:id" element={<NewsOpportunityView/>}/>
          </Route>
          <Route path="business" element={<Business/>}/>
          <Route path="portal">
            <Route index element={<GigPortal/>}/> 
            <Route path="/portal/market" element={<FestivalMarket/>}/>
            <Route path="/portal/event" element={<FestivalEvent/>}/>
            <Route path="/portal/transwerke" element={<Transwerke/>}/>
          </Route>
          <Route path="about" element={<About/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
