import PageHeader from "../shared/PageHeader";

const Events = ()=>{
    return(
        <div>
            <PageHeader title="EVENTS"/>
            <div className="max-w-screen-xl mx-auto mt-4">
                <div className="shadow-2xl rounded-md mt-3 relative border py-4 px-3">
                    <div className="absolute top-0 left-1/2 w-4 ml-[-8px] h-full rounded-full bg-zinc-200 hidden md:block">
                        <div className="relative h-4 w-4">
                            <div className="sticky top-1/2">
                                <div className="absolute h-4 w-4 animate-pulse rounded-full bg-zinc-900"></div>
                                <div className="rounded-full h-4 w-4 bg-zinc-800"></div>
                            </div>
                        </div>
                    </div>

                    <div className="grid md:grid-cols-2 grid-cols-1 gap-8 text-zinc-600">
                        <div className="flex justify-end items-end flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="flex justify-start items-start flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                        <div className="flex justify-end items-end flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="flex justify-start items-start flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                        <div className="flex justify-end items-end flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="flex justify-start items-start flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                        <div className="flex justify-end items-end flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="flex justify-start items-start flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                        <div className="flex justify-end items-end flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="flex justify-start items-start flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                        <div className="flex justify-end items-end flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="flex justify-start items-start flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                        <div className="flex justify-end items-end flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="flex justify-start items-start flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                        <div className="flex justify-end items-end flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="h-70 md:block hidden"></div>
                        <div className="flex justify-start items-start flex-col h-70">
                            {/* <div className="h-40 w-40 rounded-full bg-zinc-100"></div> */}
                            <h4>Braamfontein Rocks</h4>
                            <h5>31 November 2022</h5>
                            <h6>13h00 - 22h00</h6>
                            <div className="w-[80%] border mt-2"></div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default Events;